import React, {FunctionComponent} from "react";
import Layout from "../components/layout";
import {Container, Grid} from "../components/common";
import {Post} from "../utils/models";
import {Card} from "../components/card";
import styled from "styled-components";
import {Link} from "gatsby";
import SidebarContent from "../components/sidebar-content";
import SEO from "../components/seo";
import Theme from "../styles/theme";
import Bio from "../components/bio";
import Avatar from "../components/avatar";
import SocialChannelList from "../components/social-channel-list";

interface PostsPageProps {
  pathContext: {
    posts: Post[];
    postsPerPage: number;
  };
  location: Location;
}

const HomeContainer = styled(Container)`
  display: grid;
  grid-template-columns: minmax(0, 1fr) .25fr;
  grid-column-gap: 30px;

  @media (max-width: ${Theme.breakpoints.xl}) {
    grid-template-columns: 1fr;
  }
`;

const PostsContainer = styled(Grid)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "latest latest" ". .";
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: -30px;

  @media (max-width: ${Theme.breakpoints.sm}) {
    display: block;
    padding: 0;

    article {
      margin-bottom: 30px;
    }
  }
`;

const Sidebar = styled.aside`
  width: 315px;
  padding-top: 30px;

  @media (max-width: ${Theme.breakpoints.xl}) {
    margin: 30px auto;
    border-top: 2px #e5eff5 solid;
    padding: 20px;
    width: 100%;
  }
`;

const ArchiveLinkWrapper = styled.div`
  grid-column: 1 / -1;
  text-align: center;
`;

const ArchiveLink = styled(Link)`
  font-size: .8em;
  padding: 10px;
  border-radius: .3em;
  transition: background-color .5s;
  background-color: #f2f2f2;

  &:hover {
    background-color: #e6e6e6;
  }
`;

const StickySidebarContent = styled.div`
  position: sticky;
  top: 30px;
`;

const StyledBio = styled.section<Pick<BioProps, 'textAlign'>>`
  margin: auto;
  text-align: ${props => props.textAlign};
  width: 100%;
`;

const AuthorDescription = styled.p`
  margin: 10px 0 13px;

  a {
    color: #000;
    text-decoration: underline;
  }
`;

const AuthorName = styled.a`
  margin: 10px 10px 5px 0px;
  font-size: 1.3em;
  font-weight: bold;
`;

const DownloadButton = styled.a`
  background-color: #122D84;
  color: #fff;
  font-weight: bold;
  display: block;
  width: 100%;
  padding: 15px;
  font-size: 1.2em;
  text-align: center;
  margin: 30px 0;
  transition: .5s all;

  &:hover {
    background-color: #231f20;
  }
`;

const PostsPage: FunctionComponent<PostsPageProps> = ({pathContext, location}) => {
  const posts = pathContext.posts.slice(0, pathContext.postsPerPage);
  const lang = 'fr';
  const social = {
    facebook: `https://www.facebook.com/hoosgood/`,
    twitter: `https://twitter.com/hoosgood`,
    linkedin: `https://www.linkedin.com/company/hoosgood`,
    instagram: `https://www.instagram.com/hoosgoodworld/?hl=en`,
    youtube: `https://www.youtube.com/channel/UCt9H-CwmLy0qi08dtJQDnAg`,
    github: ``,
    twitch: ``
  };
  return (
    <Layout lang={'fr'}>
      <SEO location={location} type={`WebSite`}/>
      <HomeContainer>
        <PostsContainer>
          {posts.map((post, index) => (
            <Card
              title={post.frontmatter.title}
              path={`/${lang}${post.frontmatter.path}`}
              featuredImage={post.frontmatter.featuredImage ? post.frontmatter.featuredImage.childImageSharp : null}
              content={post.frontmatter.excerpt}
              key={index}
              meta={
                {
                  time: post.frontmatter.created,
                  timePretty: post.frontmatter.createdPretty,
                  tag: post.frontmatter.tags.length > 0 ? post.frontmatter.tags[0] : null,
                }
              }
              style={{gridArea: index === 0 ? 'latest' : undefined}}
              halfImage={index === 0}
            />
          ))}
          <ArchiveLinkWrapper>
            <ArchiveLink to={`/archive`}>Voir tous les articles</ArchiveLink>
          </ArchiveLinkWrapper>
        </PostsContainer>
        <Sidebar>
          <StickySidebarContent>
            <StyledBio textAlign="left">
              <Avatar
              alt="hoosgood"
              style={{float: `left`, margin: `0 20px 5px 0`}}
              />
              <AuthorName
              href={`https://www.hoosgood.com`}
              target={`_blank`}
              rel={`noopener`}
              >hoosgood</AuthorName>
              <AuthorDescription dangerouslySetInnerHTML={{__html: "hoosgood est un regroupement de profs qui donnent des cours en ligne ou en personne. Les profs automatisent toute la gestion des horaires, listes d'attente, annulations, modifications et les paiements par carte de crédit."}}/>
              <SocialChannelList channels={social}/>
            </StyledBio>
            <DownloadButton
              href={`https://www.hoosgood.com`}
              target={`_blank`}
              rel={`noopener`}
              aria-label={`Enseignez sur hoosgood`}
            >
              Enseignez sur hoosgood
            </DownloadButton>
          </StickySidebarContent>
        </Sidebar>
      </HomeContainer>
    </Layout>
  );
};

export default PostsPage;
